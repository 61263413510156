import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Slider from "../components/Slider";
import anime from "../utils/anime";

interface IProps {}

const Screen1 = (props: IProps) => {
  const items = [
    {
      title: "Таны хүссэн газарт хүрэх үйлчилгээ",
      desc: "Аль ч өдөр, өдрийн ямар ч цагт таны гэр эсвэл албан байгууллагад очиж үйлчилнэ.",
      image: (
        <StaticImage
          src="../images/f1.png"
          alt={"Urtuu"}
          placeholder="none"
          quality={100}
          imgStyle={{
            maxWidth: "100%",
          }}
        />
      ),
    },
    {
      title: "Чанартай баталгаат үйлчилгээ",
      desc: "“Өртөө”-гөөс захиалах үйлчилгээг тусгай зөвшөөрөлтэй, мэргэжлийн сургалтад хамрагдсан, суурь шалгах үйл явцаар шалгагдсан хүмүүс  үзүүлнэ.",
      image: (
        <StaticImage
          src="../images/f2.png"
          alt={"Urtuu"}
          placeholder="none"
          quality={100}
          imgStyle={{
            maxWidth: "100%",
          }}
        />
      ),
    },
    {
      title: "Зөвхөн танд зориулсан уян хатан үйлчилгээ",
      desc: "Таны сонгосон цаг, хүссэн газарт, таны ахуйн ажлын онцлогт нийцүүлэн үйлчлэх тул  тав тухтай байж, уян хатан зохицуулалт хийх боломж Танд бүрдэнэ. ",
      image: (
        <StaticImage
          src="../images/f3.png"
          alt={"Urtuu"}
          placeholder="none"
          quality={100}
          imgStyle={{
            maxWidth: "100%",
          }}
        />
      ),
    },
  ];
  return (
    <>
      <div className="relative overflow-hidden">
        <div className="mx-auto max-w-7xl px-8 relative">
          <div className="absolute hidden lg:block top-0 -right-1/2 lg:right-[2rem] bottom-0">
            <StaticImage
              src="../images/home.png"
              alt={"Urtuu"}
              placeholder="none"
              quality={100}
              layout="fixed"
              height={578}
            />
          </div>

          <div
            className="absolute  hidden lg:block top-0 left-0 bottom-0 h-[578px] w-[150%] lg:w-[120%] right-0"
            style={{
              background:
                "linear-gradient(90deg, #FFFFFF 15.26%, #FFFFFF 35.56%, rgba(255, 255, 255, 0) 48.36%)",
            }}
          ></div>

          <div className="flex flex-col md:flex-row lg:min-h-[600px] items-center w-full relative">
            <div className="w-full flex flex-col justify-center">
              <div className="block lg:hidden">
                <StaticImage
                  src="../images/home.png"
                  alt={"Urtuu"}
                  placeholder="none"
                  quality={100}
                />
              </div>
              <div className="text-left relative flex-col justify-center items-start">
                <span
                  className="font-bold my-5 lg:my-0 text-[21px] uppercase lg:text-[38px] mb-5 block"
                  {...anime("fade-right", 600)}
                >
                  АМЬДРАЛЫГ ТАНЬ
                  <br />
                  ХӨНГӨВЧИЛЬЕ
                </span>
                <p
                  className="text-[18px] lg:text-[24px] p-0 w-full mb-5 text-[#1E1E1E]"
                  {...anime("fade-right", 600, 300)}
                >
                  Хүссэн цагт, хүссэн газарт, <br />
                  хүссэн үйлчилгээг танд хүргэнэ.
                </p>
                <button
                  className="h-[60px] lg:h-[85px] text-[18px] mb-10 lg:mb-0 px-10 font-bold text-c1 items-center justify-center flex border-[2px] rounded-full border-c1"
                  {...anime("fade-right", 600, 600)}
                  onClick={() => {
                    try {
                      const div = document.getElementById("app-download");
                      div?.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                      });
                    } catch (e) {}
                  }}
                >
                  Захиалах
                </button>
              </div>
            </div>
          </div>
          <StaticImage
            src="../images/urtuu-back.svg"
            alt={"Urtuu"}
            placeholder="none"
            quality={100}
            // layout="fixed"
            className="max-w-full"
            imgClassName="max-w-full"
          />
        </div>

        <div className="bg-c2 py-[62px] mb-10 lg:mb-0 relative -top-[10px] lg:-top-[40px]">
          <div className="mx-auto max-w-7xl px-8 flex flex-col space-y-10 lg:space-y-0 lg:flex-row justify-between items-center">
            <div className="flex items-center space-x-10">
              <div className="p-2 border-2 border-c1 rounded-full">
                <div className="h-[60px] w-[60px] p-3 lg:p-0 lg:h-[145px] lg:w-[145px] bg-c1 rounded-full flex items-center justify-center">
                  <StaticImage
                    src="../images/u-small-white.svg"
                    alt={"Urtuu App"}
                    placeholder="none"
                    width={55}
                    height={64}
                  />
                </div>
              </div>
              <div>
                <h3 className="font-bold text-[18px] lg:text-[38px] lg:mb-6 uppercase m-0">
                  Өртөө гэр бүл
                </h3>
                <p className="m-0 text-[18px] lg:text-[24px]">
                  Өртөөд тавтай морил!
                </p>
              </div>
            </div>
            <div>
              <Link
                to="/apply"
                className="text-[18px] lg:text-[24px] italic font-bold inline-flex items-center space-x-4"
              >
                <span>
                  Үйлчилгээ үзүүлэхийг хүсвэл <br /> энд дарна уу.
                </span>
                <MdOutlineArrowForwardIos />
              </Link>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-8 mb-20">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-14">
            {items?.map((item, i) => (
              <section
                key={`item-${i}`}
                className="relative flex flex-col space-y-10"
                {...anime("fade-up", 600, i * 100)}
              >
                {item?.image}
                <h3 className="text-center font-bold text-[21px] text-left lg:text-[24px] uppercase">
                  {item?.title}
                </h3>
                <p className="text-center text-[18px] font-light">
                  {item?.desc}
                </p>
              </section>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Screen1;
