import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import anime from "../utils/anime";

const items = [
  {
    title: <>Таны гарт багтах найдвартай, хэрэглэхэд хялбар технологи</>,
  },
  {
    title: <>Таныг үйлчилгээтэй холбох хялбар апп</>,
  },
  {
    title: <>Үйлчилгээний захиалгыг автоматжуулах технологи</>,
  },
];

const Screen4 = () => {
  const list = [
    {
      title: "Зорилго",
      desc: "Амьдралыг тань хялбарчилъя.",
      image: (
        <StaticImage
          src="../images/f5.png"
          alt={"Urtuu"}
          placeholder="none"
          height={95}
          quality={100}
          imgStyle={{
            maxWidth: "100%",
          }}
        />
      ),
    },
    {
      title: "Алсын хараа",
      desc: "Айл бүрт ӨРТӨӨ.",
      image: (
        <StaticImage
          src="../images/f6.png"
          alt={"Urtuu"}
          height={95}
          placeholder="none"
          quality={100}
          imgStyle={{
            maxWidth: "100%",
          }}
        />
      ),
    },
    {
      title: "Үнэт зүйлс",
      desc: `Бидний үйлчлүүлэгч. <br/>
      Бидний гэр бүл.<br/>
      Бидний нийгэм.`,
      image: (
        <StaticImage
          src="../images/f7.png"
          alt={"Urtuu"}
          placeholder="none"
          quality={100}
          height={95}
          imgStyle={{
            maxWidth: "100%",
          }}
        />
      ),
    },
  ];
  return (
    <>
      <section className="bg-c2 py-[80px]" id="app-download">
        <div className="max-w-7xl px-8 relative mx-auto">
          <h1
            className="text-[21px] mb-20 lg:mb-0 lg:text-[38px] uppercase relative font-bold"
            {...anime("fade-up-right", 600, 200)}
          >
            Таны алган дээрх <br />
            хялбар захиалга
          </h1>
          <div className="flex flex-col lg:flex-row items-center lg:space-x-20">
            <div className="order-2 lg:order-1 text-right">
              <div className="inline-block text-left">
                {items?.map((item, i) => (
                  <section
                    key={`item-${i}`}
                    className={`flex items-center space-x-5 relative mb-5 ${
                      false ? "opacity-70" : ""
                    }`}
                    {...anime("fade-right", 600, i * 100)}
                  >
                    <div className="p-2 transform scale-75 lg:scale-100 border-2 border-black rounded-full">
                      <div className="h-[78px] w-[78px] p-3 lg:p-0 bg-black rounded-full flex items-center justify-center">
                        <StaticImage
                          src="../images/u-small-white.svg"
                          alt={"Urtuu App"}
                          placeholder="none"
                          width={29}
                          height={35}
                        />
                      </div>
                    </div>
                    <h3 className="text-[18px] lg:text-[18px]">
                      {item?.title}
                    </h3>
                  </section>
                ))}
                <div className="flex space-x-5 mt-20">
                  <a
                    href="https://apps.apple.com/mn/app/urtuu/id6446311351"
                    {...anime("fade-up", 600, items?.length * 100)}
                  >
                    <StaticImage
                      src="../images/appstore.png"
                      alt={"Urtuu App - App Store"}
                      placeholder="none"
                      height={50}
                      quality={100}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=mn.urtuu.app"
                    {...anime("fade-up", 600, items?.length * 100 + 100)}
                  >
                    <StaticImage
                      src="../images/playstore.png"
                      alt={"Urtuu App - Play Store"}
                      placeholder="none"
                      height={50}
                      quality={100}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="order-1 lg:order-2 w-full"
              {...anime("fade-up-left", 600)}
            >
              <StaticImage
                src="../images/f4.png"
                alt={"Urtuu App"}
                placeholder="none"
                height={600}
                className="pointer-events-none relative -top-10"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="max-w-7xl mx-auto px-8 my-[100px]">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
          {list?.map((l, i) => (
            <div
              {...anime("fade-up", 500, 300 * i)}
              key={`f-lis-${i}`}
              className="bg-[#F8F8F8] rounded-[20px] flex flex-col  space-y-8 justify-center items-center px-20 py-10"
            >
              {l?.image}
              <h3 className="font-bold text-[21px] text-left">{l.title}</h3>
              <p
                className="text-[#1E1E1E] text-center text-[2?px]"
                dangerouslySetInnerHTML={{ __html: l.desc }}
              ></p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Screen4;
