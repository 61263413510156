import * as React from "react";
import type { HeadFC } from "gatsby";
import Screen1 from "../containers/Screen1";
import Screen4 from "../containers/Screen4";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

const IndexPage = () => {
  return (
    <Layout>
      <Screen1 />
      <Screen4 />
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <SEO imageUrl={"../../og.png"} />;
